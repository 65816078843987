ion-avatar.textAvatar {
    --border-radius: 0.5rem;
    background: var(--ion-color-tertiary);
    display: flex;
    align-items: center;
    text-align: center;
}

ion-avatar.textAvatar ion-label {
    font-weight: bold !important;
    color: white !important;
}