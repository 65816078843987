#login-page .loginFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
}

#login-page .loginContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s ease-in-out;
}

#login-page .loginContainer .icon {
    width: 180px;
    height: 180px;
    animation: dropIn 0.8s ease-in-out;
}

#login-page .loginInputs {
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#login-page .loginInputs ion-input {
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    background: rgba(217, 217, 217, 0.40);
}

#login-page .loginButtons {
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#login-page .loginButtons ion-button {
    width: 100%;
    height: 2.5rem
}

#login-page .backgroundImg {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

@keyframes dropIn {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}