ion-col.centeredColContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ion-content.frostedGlassContent {
  --background: url(../assets/background-2.png) no-repeat center center / cover;
}

ion-content.frostedGlassContent ion-card {
  --ion-card-background: rgba(var(--ion-color-light-rgb), 0.7);
  --ion-item-background: rgba(var(--ion-color-light-rgb), 0.3);
  backdrop-filter: blur(10px);
}

ion-grid.rootContentGrid {
  max-width: 80rem;
  animation: fadeIn 0.25s ease-in-out;
}

ion-grid.rootContentGrid ion-card:first-child {
  margin-top: 0px;
}

ion-grid.rootContentGrid ion-card {
  margin: 0rem;
  margin-top: 10px;
  border-radius: 0.6rem;
}

ion-card-header.noBottomPadding {
  padding-bottom: 0px;
}

.md ion-list.cardList {
  border-radius: 0.6rem;
  padding: 0px;
}

.md ion-list.cardList ion-item-divider {
  color: inherit;
  border-color: var(--ion-color-medium);
  border-width: 2px;
}

.ios ion-list.cardList ion-item-divider {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

ion-list.cardList ion-item {
  color: inherit;
}

.md ion-list.cardList ion-item {
  --border-color: var(--ion-color-medium-shade);
}

ion-list.cardList ion-item ion-label:first-child {
  font-weight: bold;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}